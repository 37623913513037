import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row form-section visible-section shaded-section" }
const _hoisted_2 = { class: "section-body" }
const _hoisted_3 = { class: "form-column col-sm-12" }
const _hoisted_4 = ["data-fieldtype"]
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = { class: "clearfix" }
const _hoisted_7 = { class: "control-label" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "row form-section visible-section" }
const _hoisted_10 = { class: "col-sm-10" }
const _hoisted_11 = { class: "form-section-heading uppercase" }
const _hoisted_12 = { class: "col-sm-2 filter-list" }
const _hoisted_13 = { class: "sort-selector" }
const _hoisted_14 = { class: "dropdown" }
const _hoisted_15 = {
  class: "text-muted dropdown-toggle small",
  "data-toggle": "dropdown"
}
const _hoisted_16 = { class: "dropdown-text" }
const _hoisted_17 = { class: "dropdown-menu" }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = { class: "option" }
const _hoisted_20 = { class: "section-body" }
const _hoisted_21 = { class: "form-column col-sm-12" }
const _hoisted_22 = {
  class: "form-group frappe-control input-max-width",
  "data-fieldtype": "Check"
}
const _hoisted_23 = { class: "checkbox" }
const _hoisted_24 = { class: "input-area" }
const _hoisted_25 = { class: "label-area small" }
const _hoisted_26 = {
  class: "frappe-control",
  "data-fieldtype": "Table"
}
const _hoisted_27 = { class: "form-grid" }
const _hoisted_28 = { class: "grid-heading-row" }
const _hoisted_29 = { class: "grid-row" }
const _hoisted_30 = { class: "data-row row" }
const _hoisted_31 = { class: "row-index col col-xs-1" }
const _hoisted_32 = { class: "col grid-static-col col-xs-6" }
const _hoisted_33 = { class: "static-area ellipsis" }
const _hoisted_34 = { class: "col grid-static-col col-xs-2" }
const _hoisted_35 = { class: "static-area ellipsis text-right" }
const _hoisted_36 = { class: "col grid-static-col col-xs-1" }
const _hoisted_37 = { class: "static-area ellipsis text-right" }
const _hoisted_38 = { class: "col grid-static-col col-xs-1" }
const _hoisted_39 = { class: "static-area ellipsis text-right" }
const _hoisted_40 = { class: "grid-body" }
const _hoisted_41 = { class: "rows" }
const _hoisted_42 = ["onClick"]
const _hoisted_43 = { class: "row-index col col-xs-1" }
const _hoisted_44 = {
  class: "col grid-static-col col-xs-6",
  "data-fieldtype": "Code"
}
const _hoisted_45 = { class: "static-area" }
const _hoisted_46 = { class: "col grid-static-col col-xs-2" }
const _hoisted_47 = { class: "static-area ellipsis text-right" }
const _hoisted_48 = { class: "col grid-static-col col-xs-1" }
const _hoisted_49 = { class: "static-area ellipsis text-right" }
const _hoisted_50 = { class: "col grid-static-col col-xs-1" }
const _hoisted_51 = { class: "static-area ellipsis text-right" }
const _hoisted_52 = { class: "col col-xs-1" }
const _hoisted_53 = { class: "close btn-open-row" }
const _hoisted_54 = {
  key: 0,
  class: "recorder-form-in-grid"
}
const _hoisted_55 = { class: "toolbar grid-header-toolbar" }
const _hoisted_56 = { class: "panel-title" }
const _hoisted_57 = { class: "grid-form-row-index" }
const _hoisted_58 = { class: "grid-form-body" }
const _hoisted_59 = { class: "form-area" }
const _hoisted_60 = { class: "form-layout" }
const _hoisted_61 = { class: "form-page" }
const _hoisted_62 = { class: "row form-section visible-section" }
const _hoisted_63 = { class: "section-body" }
const _hoisted_64 = { class: "form-column col-sm-12" }
const _hoisted_65 = { class: "frappe-control" }
const _hoisted_66 = { class: "form-group" }
const _hoisted_67 = { class: "clearfix" }
const _hoisted_68 = { class: "control-label" }
const _hoisted_69 = { class: "control-value like-disabled-input for-description" }
const _hoisted_70 = { class: "frappe-control" }
const _hoisted_71 = { class: "form-group" }
const _hoisted_72 = { class: "clearfix" }
const _hoisted_73 = { class: "control-label" }
const _hoisted_74 = { class: "control-value like-disabled-input for-description" }
const _hoisted_75 = { class: "frappe-control input-max-width" }
const _hoisted_76 = { class: "form-group" }
const _hoisted_77 = { class: "clearfix" }
const _hoisted_78 = { class: "control-label" }
const _hoisted_79 = { class: "control-value like-disabled-input" }
const _hoisted_80 = { class: "frappe-control input-max-width" }
const _hoisted_81 = { class: "form-group" }
const _hoisted_82 = { class: "clearfix" }
const _hoisted_83 = { class: "control-label" }
const _hoisted_84 = { class: "control-value like-disabled-input" }
const _hoisted_85 = { class: "frappe-control input-max-width" }
const _hoisted_86 = { class: "form-group" }
const _hoisted_87 = { class: "clearfix" }
const _hoisted_88 = { class: "control-label" }
const _hoisted_89 = { class: "control-value like-disabled-input" }
const _hoisted_90 = { class: "frappe-control" }
const _hoisted_91 = { class: "form-group" }
const _hoisted_92 = { class: "clearfix" }
const _hoisted_93 = { class: "control-label" }
const _hoisted_94 = {
  class: "control-value like-disabled-input for-description",
  style: {"overflow":"auto"}
}
const _hoisted_95 = { class: "table table-striped" }
const _hoisted_96 = {
  key: 0,
  class: "frappe-control"
}
const _hoisted_97 = { class: "form-group" }
const _hoisted_98 = { class: "clearfix" }
const _hoisted_99 = { class: "control-label" }
const _hoisted_100 = {
  class: "control-value like-disabled-input for-description",
  style: {"overflow":"auto"}
}
const _hoisted_101 = { class: "table table-striped" }
const _hoisted_102 = {
  key: 0,
  class: "grid-empty text-center"
}
const _hoisted_103 = {
  key: 0,
  class: "list-paging-area",
  style: {"border-top":"none"}
}
const _hoisted_104 = { class: "row" }
const _hoisted_105 = { class: "col-xs-6" }
const _hoisted_106 = { class: "btn-group btn-group-paging" }
const _hoisted_107 = ["onClick"]
const _hoisted_108 = { class: "col-xs-6 text-right" }
const _hoisted_109 = { class: "btn-group btn-group-paging" }
const _hoisted_110 = ["onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("form", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.columns, (column, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["frappe-control", column.class]),
                "data-fieldtype": column.type,
                key: index
              }, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("label", _hoisted_7, _toDisplayString(column.label), 1 /* TEXT */)
                  ]),
                  _createElementVNode("div", {
                    class: "control-value like-disabled-input",
                    innerHTML: column.formatter ? column.formatter($setup.request[column.slug]) : $setup.request[column.slug]
                  }, null, 8 /* PROPS */, _hoisted_8)
                ])
              ], 10 /* CLASS, PROPS */, _hoisted_4))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("h6", _hoisted_11, _toDisplayString(_ctx.__("SQL Queries")), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("a", _hoisted_15, [
              _createElementVNode("span", _hoisted_16, _toDisplayString($setup.table_columns.filter(c => c.slug == $setup.query.sort)[0].label), 1 /* TEXT */)
            ]),
            _createElementVNode("ul", _hoisted_17, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.table_columns.filter(c => c.sortable), (column, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: index,
                  onClick: $event => ($setup.query.sort = column.slug)
                }, [
                  _createElementVNode("a", _hoisted_19, _toDisplayString(column.label), 1 /* TEXT */)
                ], 8 /* PROPS */, _hoisted_18))
              }), 128 /* KEYED_FRAGMENT */))
            ])
          ]),
          _createElementVNode("button", {
            class: "btn btn-default btn-xs btn-order",
            onClick: _cache[0] || (_cache[0] = $event => ($setup.query.order = ($setup.query.order == 'asc') ? 'desc' : 'asc'))
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(["octicon text-muted", $setup.query.order == 'asc' ? 'octicon-arrow-down' : 'octicon-arrow-up'])
            }, null, 2 /* CLASS */)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("form", null, [
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("label", null, [
                  _createElementVNode("span", _hoisted_24, [
                    _withDirectives(_createElementVNode("input", {
                      type: "checkbox",
                      class: "input-with-feedback bold",
                      "data-fieldtype": "Check",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.group_duplicates) = $event))
                    }, null, 512 /* NEED_PATCH */), [
                      [_vModelCheckbox, $setup.group_duplicates]
                    ])
                  ]),
                  _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.__("Group Duplicate Queries")), 1 /* TEXT */)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_26, [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_27, [
                  _createElementVNode("div", _hoisted_28, [
                    _createElementVNode("div", _hoisted_29, [
                      _createElementVNode("div", _hoisted_30, [
                        _createElementVNode("div", _hoisted_31, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.__("Index")), 1 /* TEXT */)
                        ]),
                        _createElementVNode("div", _hoisted_32, [
                          _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.__("Query")), 1 /* TEXT */)
                        ]),
                        _createElementVNode("div", _hoisted_34, [
                          _createElementVNode("div", _hoisted_35, _toDisplayString(_ctx.__("Duration (ms)")), 1 /* TEXT */)
                        ]),
                        _createElementVNode("div", _hoisted_36, [
                          _createElementVNode("div", _hoisted_37, _toDisplayString(_ctx.__("Exact Copies")), 1 /* TEXT */)
                        ]),
                        _createElementVNode("div", _hoisted_38, [
                          _createElementVNode("div", _hoisted_39, _toDisplayString(_ctx.__("Normalized Copies")), 1 /* TEXT */)
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_40, [
                    _createElementVNode("div", _hoisted_41, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.paginated($setup.sorted($setup.grouped($setup.request.calls))), (call) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: _normalizeClass(["grid-row", $setup.showing == call.index ? 'grid-row-open' : '']),
                          key: call.index
                        }, [
                          _createElementVNode("div", {
                            class: "data-row row",
                            onClick: $event => ($setup.showing = $setup.showing == call.index ? null : call.index)
                          }, [
                            _createElementVNode("div", _hoisted_43, [
                              _createElementVNode("span", null, _toDisplayString(call.index), 1 /* TEXT */)
                            ]),
                            _createElementVNode("div", _hoisted_44, [
                              _createElementVNode("div", _hoisted_45, [
                                _createElementVNode("span", null, _toDisplayString(call.query), 1 /* TEXT */)
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_46, [
                              _createElementVNode("div", _hoisted_47, _toDisplayString(call.duration), 1 /* TEXT */)
                            ]),
                            _createElementVNode("div", _hoisted_48, [
                              _createElementVNode("div", _hoisted_49, _toDisplayString(call.exact_copies), 1 /* TEXT */)
                            ]),
                            _createElementVNode("div", _hoisted_50, [
                              _createElementVNode("div", _hoisted_51, _toDisplayString(call.normalized_copies), 1 /* TEXT */)
                            ]),
                            _createElementVNode("div", _hoisted_52, [
                              _createElementVNode("a", _hoisted_53, [
                                _createElementVNode("span", {
                                  class: _normalizeClass(["octicon", $setup.showing == call.index? 'octicon-triangle-up' : 'octicon-triangle-down'])
                                }, null, 2 /* CLASS */)
                              ])
                            ])
                          ], 8 /* PROPS */, _hoisted_42),
                          ($setup.showing == call.index)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_54, [
                                _createElementVNode("div", {
                                  class: "grid-form-heading",
                                  onClick: _cache[2] || (_cache[2] = $event => ($setup.showing = null))
                                }, [
                                  _createElementVNode("div", _hoisted_55, [
                                    _createElementVNode("span", _hoisted_56, [
                                      _createTextVNode(_toDisplayString(_ctx.__("SQL Query")) + " #", 1 /* TEXT */),
                                      _createElementVNode("span", _hoisted_57, _toDisplayString(call.index), 1 /* TEXT */)
                                    ])
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_58, [
                                  _createElementVNode("div", _hoisted_59, [
                                    _createElementVNode("div", _hoisted_60, [
                                      _createElementVNode("div", _hoisted_61, [
                                        _createElementVNode("div", _hoisted_62, [
                                          _createElementVNode("div", _hoisted_63, [
                                            _createElementVNode("div", _hoisted_64, [
                                              _createElementVNode("form", null, [
                                                _createElementVNode("div", _hoisted_65, [
                                                  _createElementVNode("div", _hoisted_66, [
                                                    _createElementVNode("div", _hoisted_67, [
                                                      _createElementVNode("label", _hoisted_68, _toDisplayString(_ctx.__("Query")), 1 /* TEXT */)
                                                    ]),
                                                    _createElementVNode("div", _hoisted_69, [
                                                      _createElementVNode("pre", null, _toDisplayString(call.query), 1 /* TEXT */)
                                                    ])
                                                  ])
                                                ]),
                                                _createElementVNode("div", _hoisted_70, [
                                                  _createElementVNode("div", _hoisted_71, [
                                                    _createElementVNode("div", _hoisted_72, [
                                                      _createElementVNode("label", _hoisted_73, _toDisplayString(_ctx.__("Normalized Query")), 1 /* TEXT */)
                                                    ]),
                                                    _createElementVNode("div", _hoisted_74, [
                                                      _createElementVNode("pre", null, _toDisplayString(call.normalized_query), 1 /* TEXT */)
                                                    ])
                                                  ])
                                                ]),
                                                _createElementVNode("div", _hoisted_75, [
                                                  _createElementVNode("div", _hoisted_76, [
                                                    _createElementVNode("div", _hoisted_77, [
                                                      _createElementVNode("label", _hoisted_78, _toDisplayString(_ctx.__("Duration (ms)")) + "\"", 1 /* TEXT */)
                                                    ]),
                                                    _createElementVNode("div", _hoisted_79, _toDisplayString(call.duration), 1 /* TEXT */)
                                                  ])
                                                ]),
                                                _createElementVNode("div", _hoisted_80, [
                                                  _createElementVNode("div", _hoisted_81, [
                                                    _createElementVNode("div", _hoisted_82, [
                                                      _createElementVNode("label", _hoisted_83, _toDisplayString(_ctx.__("Exact Copies")), 1 /* TEXT */)
                                                    ]),
                                                    _createElementVNode("div", _hoisted_84, _toDisplayString(call.exact_copies), 1 /* TEXT */)
                                                  ])
                                                ]),
                                                _createElementVNode("div", _hoisted_85, [
                                                  _createElementVNode("div", _hoisted_86, [
                                                    _createElementVNode("div", _hoisted_87, [
                                                      _createElementVNode("label", _hoisted_88, _toDisplayString(_ctx.__("Normalized Copies")), 1 /* TEXT */)
                                                    ]),
                                                    _createElementVNode("div", _hoisted_89, _toDisplayString(call.normalized_copies), 1 /* TEXT */)
                                                  ])
                                                ]),
                                                _createElementVNode("div", _hoisted_90, [
                                                  _createElementVNode("div", _hoisted_91, [
                                                    _createElementVNode("div", _hoisted_92, [
                                                      _createElementVNode("label", _hoisted_93, _toDisplayString(_ctx.__("Stack Trace")), 1 /* TEXT */)
                                                    ]),
                                                    _createElementVNode("div", _hoisted_94, [
                                                      _createElementVNode("table", _hoisted_95, [
                                                        _createElementVNode("thead", null, [
                                                          _createElementVNode("tr", null, [
                                                            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(['filename', 'lineno', 'function'], (key) => {
                                                              return _createElementVNode("th", { key: key }, _toDisplayString(key), 1 /* TEXT */)
                                                            }), 64 /* STABLE_FRAGMENT */))
                                                          ])
                                                        ]),
                                                        _createElementVNode("tbody", null, [
                                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(call.stack, (row, index) => {
                                                            return (_openBlock(), _createElementBlock("tr", { key: index }, [
                                                              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(['filename', 'lineno', 'function'], (key) => {
                                                                return _createElementVNode("td", { key: key }, _toDisplayString(row[key]), 1 /* TEXT */)
                                                              }), 64 /* STABLE_FRAGMENT */))
                                                            ]))
                                                          }), 128 /* KEYED_FRAGMENT */))
                                                        ])
                                                      ])
                                                    ])
                                                  ])
                                                ]),
                                                (call.explain_result[0])
                                                  ? (_openBlock(), _createElementBlock("div", _hoisted_96, [
                                                      _createElementVNode("div", _hoisted_97, [
                                                        _createElementVNode("div", _hoisted_98, [
                                                          _createElementVNode("label", _hoisted_99, _toDisplayString(_ctx.__("SQL Explain")), 1 /* TEXT */)
                                                        ]),
                                                        _createElementVNode("div", _hoisted_100, [
                                                          _createElementVNode("table", _hoisted_101, [
                                                            _createElementVNode("thead", null, [
                                                              _createElementVNode("tr", null, [
                                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(call.explain_result[0]), (key) => {
                                                                  return (_openBlock(), _createElementBlock("th", { key: key }, _toDisplayString(key), 1 /* TEXT */))
                                                                }), 128 /* KEYED_FRAGMENT */))
                                                              ])
                                                            ]),
                                                            _createElementVNode("tbody", null, [
                                                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(call.explain_result, (row, index) => {
                                                                return (_openBlock(), _createElementBlock("tr", { key: index }, [
                                                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(call.explain_result[0]), (key) => {
                                                                    return (_openBlock(), _createElementBlock("td", { key: key }, _toDisplayString(row[key]), 1 /* TEXT */))
                                                                  }), 128 /* KEYED_FRAGMENT */))
                                                                ]))
                                                              }), 128 /* KEYED_FRAGMENT */))
                                                            ])
                                                          ])
                                                        ])
                                                      ])
                                                    ]))
                                                  : _createCommentVNode("v-if", true)
                                              ])
                                            ])
                                          ])
                                        ])
                                      ])
                                    ])
                                  ])
                                ])
                              ]))
                            : _createCommentVNode("v-if", true)
                        ], 2 /* CLASS */))
                      }), 128 /* KEYED_FRAGMENT */))
                    ]),
                    ($setup.request.calls.length == 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_102, _toDisplayString(_ctx.__("No Data")), 1 /* TEXT */))
                      : _createCommentVNode("v-if", true)
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      ($setup.request.calls.length != 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_103, [
            _createElementVNode("div", _hoisted_104, [
              _createElementVNode("div", _hoisted_105, [
                _createElementVNode("div", _hoisted_106, [
                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList([20, 50, 100], (limit, index) => {
                    return _createElementVNode("button", {
                      type: "button",
                      class: _normalizeClass(["btn btn-default btn-sm", $setup.query.pagination.limit == limit ? 'btn-info' : '']),
                      key: index,
                      onClick: $event => ($setup.query.pagination.limit = limit)
                    }, _toDisplayString(limit), 11 /* TEXT, CLASS, PROPS */, _hoisted_107)
                  }), 64 /* STABLE_FRAGMENT */))
                ])
              ]),
              _createElementVNode("div", _hoisted_108, [
                _createElementVNode("div", _hoisted_109, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.pages, (page, index) => {
                    return (_openBlock(), _createElementBlock("button", {
                      type: "button",
                      class: _normalizeClass(["btn btn-default btn-sm", page.status]),
                      key: index,
                      onClick: $event => ($setup.query.pagination.page = page.number)
                    }, _toDisplayString(page.label), 11 /* TEXT, CLASS, PROPS */, _hoisted_110))
                  }), 128 /* KEYED_FRAGMENT */))
                ])
              ])
            ])
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}