import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createBlock(_component_router_view, null, {
    default: _withCtx(({ Component }) => [
      (_openBlock(), _createBlock(_KeepAlive, null, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
      ], 1024 /* DYNAMIC_SLOTS */))
    ]),
    _: 1 /* STABLE */
  }))
}